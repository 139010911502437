import { loadingController } from '@/components/global-loading/global-loading-controller'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { Zero } from '@/constants'
import { bigNumberHelper } from '@/helpers/bignumber-helper'
import { FarmHandler } from '@/helpers/farm-handler'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import { action, computed, IReactionDisposer, observable, reaction, runInAction, when } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'
import { Subject, timer } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

export class FarmViewModel {
  _disposers: IReactionDisposer[] = []
  private _unsubcrible = new Subject()

  @observable farmDialogInput = ''
  @observable isShowFarmDialog = false
  @observable isDialogFarm = false
  @observable isDialogLoading = false

  @observable approved = false
  @observable approving = false
  @observable harvesting = false

  @observable lockDuration = moment.duration(0, 'second') // in seconds
  @observable totalLiquidity = FixedNumber.from('0')
  @observable annualPercentageRate = FixedNumber.from('0')

  @observable stakedLP = FixedNumber.from('0')
  @observable userLPBalance = FixedNumber.from('0')
  @observable rewardAmount = FixedNumber.from('0')
  @observable lastStakeTime: moment.Moment | null = null
  farmHandler?: FarmHandler

  @observable stakedV1Amount = FixedNumber.from('0')

  constructor() {
    this.loadData()
  }

  @computed get stakedOnOldFarm() {
    return !this.stakedV1Amount.isZero()
  }

  destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
    this._disposers.forEach(d => d())
  }

  async loadData() {
    const farmHandler = new FarmHandler()
    this.farmHandler = farmHandler
    try {
      await farmHandler.load()
    } catch (error) {
      console.error('loadData', error)
    }
    this.lockDuration = farmHandler.lockDuration!
    timer(0, 30000)
      .pipe(takeUntil(this._unsubcrible))
      .subscribe(async () => {
        const { poolLiquidityUsd, apy } = await farmHandler.getFarmLiquidityAndApy()
        runInAction(() => {
          this.totalLiquidity = poolLiquidityUsd
          this.annualPercentageRate = apy
        })
      })
    this._disposers.push(
      when(
        () => walletStore.connected,
        async () => {
          if (walletStore.chainId === 56) {
            farmHandler.injectMetamask(walletStore.web3!)
            farmHandler.approved(walletStore.account).then(approved => runInAction(() => (this.approved = approved)))
            timer(0, 5000)
              .pipe(takeUntil(this._unsubcrible))
              .subscribe(async () => {
                this.fetchPoolInfo()
              })

            const stakedOldFarm = await farmHandler.getOldStaked(walletStore.account)
            if (!stakedOldFarm.isZero()) {
              runInAction(() => (this.stakedV1Amount = stakedOldFarm))
            }
          }
        }
      )
    )
  }

  @asyncAction *unstakeOldFarm() {
    loadingController.increaseRequest()
    try {
      yield this.farmHandler?.unstakeV1(walletStore.account)
      this.stakedV1Amount = FixedNumber.from('0')
    } finally {
      loadingController.decreaseRequest()
    }
  }

  async fetchPoolInfo() {
    if (!walletStore.account || !this.farmHandler) {
      this.stakedLP = FixedNumber.from('0')
    } else {
      const [{ amount, lastStakeTime }, userLPBalance, rewardAmount] = await Promise.all([
        this.farmHandler.getUserInfo(walletStore.account),
        this.farmHandler.getUserLPBalance(walletStore.account),
        this.farmHandler.getRewardAmount(walletStore.account)
      ])
      this.stakedLP = amount
      this.lastStakeTime = lastStakeTime
      this.userLPBalance = userLPBalance
      this.rewardAmount = rewardAmount
    }
  }

  @asyncAction *harvest() {
    this.harvesting = true
    try {
      yield this.farmHandler!.harvest(walletStore.account)
      this.fetchPoolInfo()
      snackController.success('Harvest successful')
    } catch (error) {
      snackController.error(error.message)
    }
    this.harvesting = false
  }

  @asyncAction *approve() {
    this.approving = true
    try {
      yield this.farmHandler!.approve(walletStore.account)
      this.approved = true
    } catch (error) {
      snackController.error(error.message)
    }
    this.approving = false
  }

  @action.bound changeFarmDialogInput(input) {
    this.farmDialogInput = input
  }

  @action.bound requestStakeLP() {
    this.isShowFarmDialog = true
    this.isDialogFarm = true
  }

  @action.bound requestUnstakeLP() {
    this.isShowFarmDialog = true
    this.isDialogFarm = false
  }

  @action.bound maximum() {
    this.farmDialogInput = this.maxDialogFarmBalance.toString()
  }

  @asyncAction *confirm() {
    this.isDialogLoading = true
    try {
      if (this.isDialogFarm) {
        yield this.farmHandler!.stakeLP(walletStore.account, this.farmDialogInput)
        snackController.success('Stake LP successful')
      } else {
        yield this.farmHandler!.unstakeLP(walletStore.account, this.farmDialogInput)
        snackController.success('Unstake LP successful')
      }
      this.fetchPoolInfo()
      this.farmDialogInput = '0'
    } catch (err) {
      snackController.error(err.message)
    } finally {
      this.isDialogLoading = false
    }
  }

  @action.bound cancelFarmDialog() {
    this.farmDialogInput = '0'
    this.isShowFarmDialog = false
  }

  @computed get isStaked() {
    return bigNumberHelper.gt(this.stakedLP, FixedNumber.from('0'))
  }

  @computed get maxDialogFarmBalance() {
    return this.isDialogFarm ? this.userLPBalance : this.stakedLP
  }

  @computed get lockInDays() {
    return this.lockDuration.asDays()
  }

  @computed get canUnstakeTime() {
    if (this.lastStakeTime) {
      return this.lastStakeTime.clone().add(this.lockDuration)
    }
    return null
  }

  @computed get canHarvest() {
    return bigNumberHelper.gt(this.rewardAmount, Zero)
  }

  @computed get validDialogInputAmount() {
    try {
      const amount = FixedNumber.from(this.farmDialogInput)
      if (!this.isDialogFarm && this.canUnstakeTime && this.canUnstakeTime.isAfter(moment())) return false
      if (amount.isNegative() || amount.isZero()) return false
      if (this.isDialogFarm) {
        return bigNumberHelper.lte(amount, this.userLPBalance)
      } else {
        return bigNumberHelper.lte(amount, this.stakedLP)
      }
    } catch (error) {
      return false
    }
  }
}
