
























































































































import { FarmViewModel } from '../viewmodels/farm-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Vue, Provide } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {
    'farm-lp-dialog': () => import('../dialogs/farm-lp-dialog.vue')
  }
})
export default class Farm extends Vue {
  @Provide() vm = new FarmViewModel()
  walletStore = walletStore

  getLP() {
    window.open(`https://pancakeswap.finance/v2/add/BNB/${this.vm.farmHandler!.rewardToken}`, '_blank')
  }
  viewLPContract() {
    window.open(`https://bscscan.com/address/${this.vm.farmHandler!.poolToken}`, '_blank')
  }
  seePool() {
    window.open(`https://pancakeswap.info/pool/${this.vm.farmHandler!.rewardToken}`, '_blank')
  }
  getBSL() {
    window.open(`https://pancakeswap.finance/swap?outputCurrency=${this.vm.farmHandler!.rewardToken}`, '_blank')
  }

  destroyed() {
    this.vm.destroy()
  }
}
